<template>
  <datepicker
    :placeholder="placeholder"
    v-model="datetime"
    bootstrap-styling
    format="yyyy/MM/dd"
    :language="zh"
    class="app-datetime-picker"
    popover-align="center"
    @input="handleInput"
    v-bind="$attrs"
  >
  </datepicker>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";

export default {
  props: {
    value: [Date, String],
    toFirstSecond: {
      type: Boolean,
      default: false,
    },
    toLastSecond: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      zh,
      datetime: null,
    }
  },
  watch: {
    value() {
      this.datetime = this.value
    },
  },
  mounted() {
    this.checkForProperConfiguration()

    this.$watch(() => `${this.toFirstSecond}${this.toLastSecond}`, this.checkForProperConfiguration)

    this.datetime = this.value
  },
  methods: {
    checkForProperConfiguration() {
      if (this.toFirstSecond === true && this.toLastSecond === true) {
        console.warn('[AppDatetimePicker] Do not set both "toFirstSecond" and "toLastSecond" to be true.')
      }
    },
    handleInput() {
      if (this.toFirstSecond) {
        this.$emit("input", moment(this.datetime).startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        return
      }

      if (this.toLastSecond) {
        this.$emit("input", moment(this.datetime).endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        return
      }

      this.$emit("input", moment(this.datetime).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style lang="scss" scoped>
/* provide bootstrap styling overwrites */

.app-datetime-picker {
  padding: 0;
}

::v-deep.app-datetime-picker input {
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16px;
  color: var(--liff-primary_text_color);

  &:focus {
    border: 1px solid var(--liff-primary_text_color);
  }
}

/* TODO: provide other styling variants */
</style>
