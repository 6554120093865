import https from "./https";
import store from "@/store";

const bookingPresetApi = {
  async get(id) {
    const organization = store.state.liffGeneral.orgCode
    let { data } = await https.get(`${organization}/liff/booking-presets/${id}`)
    return data.data
  },
};

export default bookingPresetApi
