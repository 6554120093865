<template>
<div class="s-layout" :style="colorObject">
  <div class="s-container">
    <Header
      :meta="meta"
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
    />

    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <div class="page">
      <div class="p-3 liff-reservation-form">
        <b-card header-tag="header" class="mb-3 liff-reservation-form__card">
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0">預約資訊</h6>
            </div>
          </template>

          <SubjectLayout :subject="subjectForStartAt" style="margin-bottom: 24px">
            <DatetimePicker v-model="start_at" :to-first-second="true" />
          </SubjectLayout>

          <SubjectLayout :subject="subjectForEndAt" style="margin-bottom: 24px">
            <DatetimePicker v-model="end_at" :to-last-second="true" />
          </SubjectLayout>

          <DynamicForm
            ref="dynamicForm"
            v-if="false == isLoading"
            :input-subjects="subjects"
            v-model="dynamicFormInput"
          >
          </DynamicForm>
        </b-card>

        <div class="d-flex s-space-x-2">
          <SharedButton class="s-btn-outline-primary w-50">上一步</SharedButton>
          <SharedButton class="s-btn-bg-primary w-50" @click="submit">送出</SharedButton>
        </div>
      </div>
    </div>
	</div>
</div>
</template>

<script>
import bookingApi from '@/apis/liff/v2/booking'
import bookingPresetApi from '@/apis/liff/v2/booking-preset'
import DatetimePicker from "@/components/AppDatetimePicker.vue";
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import SubjectLayout from "@/components/DynamicForm/SubjectLayout.vue";
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';
import Header from '@/components/Page/Liff/Shared/HeaderV3'
import Loading from "@/components/Page/Liff/Shared/Loading";
import { themeColorFn } from "@/mixins/liff/themeColor";
import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from 'vuex';

export default {
	mixins: [
    themeColorFn({ themeConfigPage: 'booking' })
  ],
	components: {
    DatetimePicker,
    DynamicForm,
    SubjectLayout,
    SharedButton,
		Header,
    Loading,
	},
	computed: {
		...mapGetters({
			meta: "liffGeneral/meta",
      themeConfig: 'liffGeneral/themeConfig',
    }),
    bookingPresetId() {
      return this.$route.params.bookingPresetId
    },
	},
  data() {
    return {
      bookingPreset: {},
      start_at: null,
      end_at: null,
      dynamicFormInput: {},
      isLoading: true,
      subjects: [],
      subjectForStartAt: {
        id: uuidv4(),
        title: '開始時間',
        required: true,
        memo: null,
      },
      subjectForEndAt: {
        id: uuidv4(),
        title: '結束時間',
        required: true,
        memo: null,
      },
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchFields()
    this.isLoading = false
  },
  methods: {
    getModuleConfig: generateModuleConfigGetter('liff_booking'),
    async fetchFields() {
      const res = await bookingPresetApi.get(this.bookingPresetId)
      this.bookingPreset = res
      this.subjects = res.config.fields.map(field => {
        field.id = field.config._id
        return field
      })
    },
    async submit() {
      this.isLoading = true
      try {
        await bookingApi.saveBooking({
          preset_id: this.bookingPresetId,
          start_at: this.start_at,
          end_at: this.end_at,
          fields: this.dynamicFormInput,
        })
        this.$swal.fire({ title: "送出成功", type: "success" })
      } catch (e) {
        console.error(e)
        this.$swal.fire({ title: "送出失敗", type: "error" })
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;

    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}

.liff-reservation-form {
	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>
